import React from "react"
import { Link } from "gatsby"
import tw from "twin.macro"
import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"

const NotFoundContainer = tw.div`
text-lg md:text-2xl text-black bg-white transition-all duration-300 dark:bg-black dark:text-gray-200 flex flex-wrap content-between min-h-screen
`
const NotFoundPage = () => {
  return (
    <NotFoundContainer>
      <Seo title="Oups, 404" />

                <Header />
        <div tw="flex relative items-center px-6 mb-8 w-full md:px-20">
          <div tw="relative z-20 w-full text-4xl leading-tight md:text-7xl">
            <p className="animate-fade" tw="hidden leading-tight lg:inline-flex">
              Perdu ?
            </p>
            <p className="animate-fade" tw="inline-flex leading-tight lg:hidden">
            Perdu ? 
            </p>
            <br />
            <p className="animate-fade" tw="hidden leading-tight lg:inline-flex">
              <Link to="/" tw="block"  data-sal="fade" data-sal-delay="50" data-sal-duration="1000">
                    <span tw="font-light border-b-2 border-gray-900 border-solid">
                      Revenez sur le droit chemin.
                    </span>
              </Link>
            </p>
            <p className="animate-fade" tw="inline-flex leading-tight lg:hidden">
              <Link to="/" tw="block"  data-sal="fade" data-sal-delay="150" data-sal-duration="1000">
                    <span tw="font-light border-b-2 border-gray-900 border-solid dark:border-gray-200">
                      Revenez sur le droit chemin.
                    </span>
              </Link>
            </p>
          </div>
          <div tw="flex absolute top-0 right-0 bottom-0 left-0 z-10 items-center w-full h-full"></div>
        </div>
        <Footer />

    </NotFoundContainer>
    
  )
}

export default NotFoundPage